import Topic from './Topic';
import helpLinks from './helpLinks';
import { Heading } from './ui';

const ListHelpLinks = () => {
    return (
        <div className='help'>
            <Heading className="help__heading" titleText="CAN'T FIND THE HELP YOU NEED?" />
            <div className='faq__help help'>
                {helpLinks.map((topic, idx) => (
                    <Topic
                        key={idx}
                        topic={topic}
                    />
                ))}
            </div>
        </div>
    );
};
export default ListHelpLinks;
