const Topic = ({ topic }) => {
    //<span className={"topic__icon topic__icon--front " + topic.class}></span>
    const svgHTML = topic.svg;

    return (
        <div className='help__topic topic'>
            <a
                href={`/${topic.url}/`}
                className='topic__content'>
                <div className='topic__left'>
                    <span
                        className={
                            'topic__icon topic__icon--front ' + topic.class
                        }
                        dangerouslySetInnerHTML={{ __html: svgHTML }}
                    />
                </div>
                <div className='topic__right'>
                    <p className='topic__text'>{topic.name}</p>
                    <p className='topic__subtext'>{topic.text}</p>
                </div>
            </a>
        </div>
    );
};

export default Topic;
